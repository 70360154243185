import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import banner from "../../assets/image/inner/banner1.png";


const About = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <div className="bg-default-6 pt-18 position-relative">
        <div
          className="hero-area bg-image dark-mode-texts"
          css={`
            background-image: url(${banner});
          `}
        >
          <Container>
            <Row className="justify-content-sm-end">
              <Col >
                <div className="hero-content pe-xl-16 pt-14 pt-lg-24 pb-md-14 pb-lg-24 mb-9 mb-md-0">
                  <h1 className="title text-center gr-text-2 mb-8 heading-color">Contact Us</h1>
                  <p className="text-center text-color-opacity">
                    Have a question? We would love to hear from you!
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default About;