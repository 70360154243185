import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/about/Hero";
import Cta1 from "../sections/landing6/Content3";

const Contact = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      >
        <Hero />
        <div style={{"background": "#fff"}} className="contact-section pb-5 pt-md-29 pb-md-29  ">
          <Container>
            <Row className="justify-content-center">
              <Col
                sm="5"
                md="6"
                lg="5"
                xl="5"
                className="offset-lg-1 d-flex flex-column justify-content-center mb-9 order-sm-2"
              >
                <div className="single-contact-widget mb-13">
                  <h3 className="gr-text-6 mb-5 heading-color">Call us</h3>
                  <p className="gr-text-7 mb-0 text-color-opacity">
                    +1 (443) 607-2903
                  </p>
                </div>
                <div className="single-contact-widget mb-13">
                  <h3 className="gr-text-6 mb-5 heading-color">Email us</h3>
                  <p className="gr-text-7 mb-0 text-color-opacity">
                    info@ainintel.com
                  </p>
                </div>
                <div className="single-contact-widget">
                  <h3 className="gr-text-6 mb-5 heading-color">Visit us</h3>
                  <p className="gr-text-7 mb-0 text-color-opacity">
                      111 Winter Street, Hagerstown <br></br>
                      Maryland 21740 USA
                  </p>
                </div>
              </Col>
              <Col sm="7" md="6" lg="5" xl="4" className="order-sm-1">
                <div className="login-form bg-white border-gray-3 px- pt-8 pb-9 rounded-10">
                  <form
                    name="contact1"
                    method="post"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                  >
                    {/* You still need to add the hidden input with the form name to your JSX form */}
                    <input type="hidden" name="form-name" value="contact1" />
                    <div className="form-group mb-6">
                      <label
                        htmlFor="name"
                        className="gr-text-11 fw-bold text-blackish-blue"
                      >
                        Name
                      </label>
                      <input
                        className="form-control px-7 gr-text-11 border mt-3 bg-gray-2"
                        type="text"
                        id="name"
                        name="name"
                        required
                        style={{"borderRadius": "0px", "padding": "0.5rem 1rem"}}
                      />
                    </div>
                    <div className="form-group mb-6">
                      <label
                        htmlFor="name"
                        className="gr-text-11 fw-bold text-blackish-blue"
                      >
                        Email
                      </label>
                      <input
                        className="form-control px-7 gr-text-11 border mt-3 bg-gray-2"
                        type="text"
                        id="email"
                        name="email"
                        required
                        style={{"borderRadius": "0px", "padding": "0.5rem 1rem"}}
                      />
                    </div>
                    <div className="form-group mb-6">
                      <label
                        htmlFor="name"
                        className="gr-text-11 fw-bold text-blackish-blue"
                      >
                        Subject
                      </label>
                      <input
                        className="form-control px-7 gr-text-11 border mt-3 bg-gray-2"
                        type="text"
                        id="subject"
                        name="subject"
                        required
                        style={{"borderRadius": "0px", "padding": "0.5rem 1rem"}}
                      />
                    </div>
                    <div className="form-group mb-6">
                      <label
                        htmlFor="name"
                        className="gr-text-11 fw-bold text-blackish-blue"
                      >
                        Message
                      </label>
                      <textarea
                        id="message"
                        name="message"
                        className="form-control px-7 gr-text-11 border mt-3 bg-gray-2"
                        required
                        style={{"borderRadius": "0px", "padding": "0.5rem 1rem"}}
                      ></textarea>
                    </div>
                    <div className="button-block mb-2">
                      <Button className="form-btn w-50" style={{"background": "#1171AB"}}type="submit">
                        Send
                      </Button>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default Contact;
